import { Button, Space, Switch, Table, Tabs, Tooltip } from "antd";
import Column from "antd/es/table/Column";
import jwtDecode from "jwt-decode";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { BsArrowUpRight, BsLink45Deg } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { MainLogo } from "../../assets/Svgs/GUTERTIPP";
import PromoterPublicService from "../../service/PromoterPublicService";
import { copyToClipboard } from "../../utils/CopyToClipboard";
import { removeToken, storeToken } from "../../utils/LocalStorageHandler";
import FloatLabel from "../FloatingLabel";
import LoadingScreen from "../_NEW/LandingPage/loadingScreen/LoadingScreen";
const Index = () => {
  const { token } = useParams();
  const [promoter, setPromoter] = useState(null);
  const [creditsRedeem, setCreditRedeem] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [promoterLinks, setPromoterLinks] = useState([]);

  const getPromoter = useCallback(async () => {
    try {
      removeToken();
      storeToken({
        access_token: token,
        refresh_token: token,
      });
      PromoterPublicService.getPromotionslinks().then((res) => {
        setPromoterLinks(res.data);
      });

      try {
        const decodedToken = jwtDecode(token);
        console.log(decodedToken);

        const { data } = await PromoterPublicService.detail(
          decodedToken.promoterId
        );
        setPromoter(data);
      } catch (error) {}
    } catch (error) {}
  }, [token]);
  useEffect(() => {
    getPromoter();
  }, [getPromoter]);

  if (!promoter) {
    return <LoadingScreen />;
  }
  return (
    <div className="max-w-[2000px] m-auto flex flex-col justify-center items-center">
      <div className="mt-10">
        <MainLogo />
      </div>
      <div className="secondary:w-[60%] primary:w-[80%]  m-auto my-20 px-3">
        <h1 className="text-4xl font-semibold mb-4">
          <span className="text-blue-main">Mein Empfehlungsportal</span>{" "}
        </h1>

        <Tabs
          defaultActiveKey="2"
          items={[
            {
              key: "1",
              label: "Gutscheine einlösen",
              children: (
                <>
                  <h1 className="text-9xl font-semibold">
                    Hallo{" "}
                    <span className="text-trkis font-bold mx-1">{`${promoter?.firstname} ${promoter?.lastname}`}</span>
                    , willkommen in Deinem{" "}
                    <span className="text-blue-main mx-1">
                      {" "}
                      Empfehlungsportal
                    </span>
                    .{" "}
                  </h1>
                  <div className="text-text-sec grid gap-10">
                    <p>
                      Nur wer etwas gutes weiterempfiehlt, gibt auch Anderen die
                      Chance etwas gutes kennen zu lernen.{" "}
                    </p>
                    <p>
                      So findest Du hier stets Dein persönliches Dankeschön von{" "}
                      {`${promoter?.user_id?.firstname} ${promoter?.user_id?.lastname}`}{" "}
                      als Wertschätzung Deines Einsatzes.{" "}
                    </p>
                  </div>
                  <div className="flex  gap-5 my-10 primary:flex-nowrap flex-wrap">
                    <div className="grad-one-bg flex items-center h-[90px] justify-center gap-5 text-white rounded-[15px]  sm:w-[500px]  w-full">
                      <span className="text-xl font-semibold">GUTHABEN:</span>
                      <span className="text-primary font-bold">
                        {String((promoter?.credits ?? 0)?.toFixed?.(2)).replace(
                          ".",
                          ","
                        )}
                        €
                      </span>
                    </div>
                    <FloatLabel
                      keepitfocused
                      label={"20€-200€"}
                      className={
                        "h-[90px] flex justify-center border border-[#d8d8d8] p-[30px_25px] rounded-sm "
                      }
                    >
                      <input
                        type="number"
                        value={creditsRedeem}
                        onChange={(e) => setCreditRedeem(e.target.value)}
                        className="bg-transparent text-xl font-semibold  outline-none sm:w-[167px] appearance-none"
                        placeholder="5-200"
                      />
                    </FloatLabel>
                    <Button
                      loading={submitting}
                      className="grad-two-bg flex items-center  cursor-pointer h-[90px] justify-center text-white rounded-[15px] w-[330px] text-xl font-semibold"
                      onClick={() => {
                        if (submitting) return;
                        setSubmitting(true);
                        PromoterPublicService.createRedeemRequest(creditsRedeem)
                          .then((e) => {
                            setPromoter((prev) => ({
                              ...prev,
                              credits: prev.credits - creditsRedeem,
                            }));

                            setCreditRedeem(0);
                          })
                          .catch((e) => console.log(e))
                          .finally(() => setSubmitting(false));
                      }}
                    >
                      EINLÖSEN
                    </Button>
                  </div>
                  <div className="text-text-sec grid gap-10 font-semibold">
                    <p>
                      Löse Dein Guthaben ein und erhalt Deinen Gutschein in
                      wenigen Tagen direkt von Cadooz per Email. Diesen kannst
                      Du dann bei cadooz.com nach Belieben in viele tolle
                      Prämien und Gutscheine einlösen.
                    </p>
                    <p>
                      Und so einfach geht's: <br />
                      Gehen Sie auf{" "}
                      <a
                        href="https://www.einloesen.de/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-black underline"
                      >
                        www.einloesen.de
                      </a>
                    </p>
                  </div>
                  <ul className=" w-full animate-fade-right  hero-list text-[#001D47] text-xl font-bold my-10">
                    <li className=""> Gutscheincode eingeben</li>
                    <li className="">Gutscheine aussuchen </li>
                    <li className="">Lieferadresse eingeben</li>
                    <li className="">
                      Wunschprodukt per Post oder E-Mail erhalten
                    </li>
                  </ul>
                  <div className="text-trkis font-semibold text-xl">
                    Auswahl aus über 700 Gutscheinen und Sachprämien!
                  </div>
                </>
              ),
            },

            {
              key: "2",
              label: "Promotionslinks",
              children: (
                <>
                  <div className=" ms-3   ">
                    <Table
                      bordered={false}
                      className="overflow-auto   "
                      dataSource={[...promoterLinks].map((x, index) => ({
                        ...x,
                        index,
                      }))}
                      pagination={false}
                      rowKey={"uid"}
                    >
                      {[...promoterLinks].length > 0 ? (
                        <>
                          <Column
                            title="Typ"
                            render={(e, _, i) => (
                              <div className="flex gap-2 items-center">
                                <div className=" ">
                                  <h5 key={i}>
                                    {e?.landingpage_id?.profession}
                                  </h5>
                                </div>

                                <a
                                  href={
                                    process.env.REACT_APP_SHORTENER_APP_LINK +
                                    `/${e?.promoter_id?.shortCode}/${e.num}`
                                  }
                                  target="_blank"
                                >
                                  <BsArrowUpRight className="cursor-pointer" />
                                </a>

                                <Tooltip
                                  title="Kopiert"
                                  color="green"
                                  trigger={"click"}
                                  mouseLeaveDelay={100}
                                  prefixCls="ant-custom-tooltip"
                                >
                                  <BsLink45Deg
                                    className="cursor-pointer"
                                    onClick={() => {
                                      const link =
                                        process.env
                                          .REACT_APP_SHORTENER_APP_LINK +
                                        `/${e?.promoter_id?.shortCode}/${e.num}`;

                                      let finalText = e?.begleittext
                                        ?.replace?.(
                                          /{Promoter Vorname}/g,
                                          e?.promoter_id?.firstname
                                        )
                                        ?.replace?.(
                                          /{Promoter Nachname}/g,
                                          e?.promoter_id?.lastname
                                        )
                                        ?.replace?.(
                                          /{Partner Vorname}/g,
                                          promoter?.user_id?.firstname
                                        )
                                        ?.replace?.(
                                          /{Partner Nachname}/g,
                                          promoter?.user_id?.lastname
                                        )
                                        ?.replace?.(/{Promolink}/g, link);

                                      if (!finalText.includes(link))
                                        finalText += ` ${link}`;

                                      copyToClipboard(finalText);
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            )}
                            className="font-bold text-[16px]"
                          ></Column>

                          <Column
                            title="Beschreibung"
                            className="font-bold text-[16px]"
                            render={(_) => _?.begleittext ?? ""}
                          ></Column>

                          <Column
                            title="Prämie"
                            className="font-bold text-[16px]"
                            render={(x) => (
                              <div className="w-[140px]">
                                <div>Klick: {x.eurProKlick}€</div>
                                <div>Empfehlung: {x.eurProEmpfehlung}€</div>
                                <div>Kunde: {x.eurProKunde}€</div>
                              </div>
                            )}
                          ></Column>

                          <Column
                            title="Aktion"
                            className="font-bold text-[16px]"
                            render={(e, i) => (
                              <Button
                                className="grad-two-bg flex items-center  cursor-pointer  justify-center text-white rounded-[10px]  text-xl font-semibold"
                                onClick={() => {
                                  const link =
                                    process.env.REACT_APP_SHORTENER_APP_LINK +
                                    `/${e?.promoter_id?.shortCode}/${e.num}`;

                                  let finalText = e?.begleittext
                                    ?.replace(
                                      /{Promoter Vorname}/g,
                                      e?.promoter_id?.firstname
                                    )
                                    .replace(
                                      /{Promoter Nachname}/g,
                                      e?.promoter_id?.lastname
                                    )
                                    ?.replace(
                                      /{Partner Vorname}/g,
                                      promoter?.user_id?.firstname
                                    )
                                    .replace(
                                      /{Partner Nachname}/g,
                                      promoter?.user_id?.lastname
                                    )
                                    .replace(/{Promolink}/g, link);

                                  if (!finalText.includes(link))
                                    finalText += ` ${link}`;

                                  window.open(
                                    `https://wa.me/?text=${finalText}`,
                                    "_blank"
                                  );
                                }}
                              >
                                Jetzt Empfehlen
                              </Button>
                            )}
                          ></Column>
                        </>
                      ) : (
                        <></>
                      )}
                    </Table>
                  </div>
                </>
              ),
            },
          ]}
        />

        <div>
          <h1 className="text-xl font-bold my-10 mt-[100px]">
            Rechtlicher Hinweis: Prämien und Guthaben
          </h1>
          <div>
            Dieses Empfehlungsportal ermöglicht es Promotern/-innen, Ihr
            Guthaben einzusehen und einzulösen. Wir möchten ausdrücklich darauf
            hinweisen, dass nur die Verwaltung der Prämien und Guthaben vom
            Seitenbetreiber EinGuterTipp zur Verfügung gestellt wird. Es besteht
            kein Rechtsanspruch gegen EinGuterTipp auf die Verfügbarkeit und
            Gewährung von Prämien oder Guthaben. Für sämtliche Prämien, die
            gegenüber Promotern/-innen zugesagt und einlöst werden, ist Sabine
            Meyer in vollem Umfang verantwortlich. Der Seitenbetreiber hat keine
            Einfluss auf die zwischen{" "}
            {`${promoter?.firstname} ${promoter?.lastname}`} und den
            Promotern/-innen getroffenen Vereinbarungen und übernimmt daher auch
            keine Verpflichtung oder Haftung in diesem Zusammenhang. Die
            Finanzierung und Gewährleistung der Prämien liegt in der alleinigen
            Verantwortung des Partners{" "}
            {`${promoter?.firstname} ${promoter?.lastname}`}. Jegliche
            Ansprüche, Streitigkeiten oder Fragen bezüglich der Prämien oder des
            Guthabens sind direkt zwischen dem Nutzer/Partner und dem jeweiligen
            Promoter zu klären. Bitte beachten Sie, dass die Verfügbarkeit von
            Prämien und Guthaben von verschiedenen Faktoren abhängig ist. So
            kann der Dienst durch den Seitenbetreiber jederzeit angepasst werden
            oder vorübergehenden Einschränkungen unterliegen. Vielen Dank für
            Ihre Nutzung des Empfehlungsportals.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
