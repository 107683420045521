import React, { useState, useEffect } from "react";
import { Table, Input } from "antd";
import AnalyticService from "../../../service/AnalyticService";
import "./LandingPageTable.css";
const LandingPageTable = () => {
  const [searchByName, setSearchByName] = useState("");
  const [stats, setStats] = useState([]);

  useEffect(() => {
    const getUserAnalytics = async () => {
      try {
        const { data } = await AnalyticService.getAdminUserAnalytics();
        setStats(data.data);
        console.log(data.data);
      } catch (error) {
        console.error("Error fetching analytics:", error);
      }
    };
    getUserAnalytics();
  }, []);

  const generateColumn = (title, dataIndex, sorter = true) => ({
    title,
    dataIndex,
    sorter: sorter
      ? (a, b) =>
          typeof a[dataIndex] === "string"
            ? a[dataIndex].localeCompare(b[dataIndex])
            : a[dataIndex] - b[dataIndex]
      : undefined,
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      onFilter: (value, record) =>
        record.name
          ? record.name.toLowerCase().includes(value.toLowerCase())
          : false,
      filteredValue: searchByName ? [searchByName] : null,
    },
    generateColumn("Landing Page", "landingPage"),
    generateColumn("Aktive Nutzer", "user"),
    generateColumn("Aufrufe", "views"),
    generateColumn("Clicks Video 1", "clicksVideo1"),
    generateColumn("Clicks Video 2", "clicksVideo2"),
    generateColumn("Watchtime Video 1", "watchTime1"),
    generateColumn("Watchtime Video 2", "watchTime2"),
    generateColumn("Whatsapp 1", "whatsapp1", false),
    generateColumn("Whatsapp 2", "whatsapp2", false),
    generateColumn("Whatsapp 3", "whatsapp3", false),
    generateColumn("Question Form", "questionform", false),
    generateColumn("Question 1", "question1", false),
    generateColumn("Question 2", "question2", false),
    generateColumn("Question 3", "question3", false),
    generateColumn("Question 4", "question4", false),
  ];

  const data = stats.map((d) => ({
    key: d.lpId,
    name: d.username,
    user: d.activeUsers,
    views: d.page_viewLP,
    landingPage: d.lpName,
    clicksVideo1: d.Play_v1 + "%",
    clicksVideo2: d.play_v2 + "%",
    watchTime1: d.Watchtime_v1 + "%",
    watchTime2: d.watchtime_v2 + "%",
    whatsapp1: d.Click_wa1 + "%",
    whatsapp2: d.Click_wa2 + "%",
    whatsapp3: d.Click_wa3 + "%",
    questionform: d.click_qf + "%",
    question1: d.click_q1 + "%",
    question2: d.click_q2 + "%",
    question3: d.click_q3 + "%",
    question4: d.click_q4 + "%",
  }));

  const handleTableChange = (pagination, filters, sorter, extra) => {
    console.log("Table params:", pagination, filters, sorter, extra);
  };

  return (
    <div
      className="self-start w-full text-nowrap custom-scrollbar"
      style={{ overflowX: "auto" }}
    >
      <Input
        placeholder="Filter by name"
        onChange={(e) => setSearchByName(e.target.value)}
        value={searchByName}
        style={{ marginBottom: 16, width: 200 }}
      />
      <Table
        className="w-full"
        pagination={{ position: ["bottomLeft"] }}
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        scroll={{ x: 1422 }}
      />
    </div>
  );
};

export default LandingPageTable;
