import React from "react";
import { imageBackground } from "../../../../../utils/setImageBackground";
import Video from "../../shared/Video";
import WhatsappButton from "../../shared/WhatsappButton";
import BlackArrow from "./BlackArrow";
import { sendEventUser } from "../../../../../utils/analytics";

const HeroFinance = ({ landingPageData }) => {
  const {
    transformed_accompanyingTextOne,
    transformed_accompanyingTextTwo,
    transformed_accompanyingTextThree,
    transformed_introductoryText,
    transformed_whatsappText,
    whatsappNumber,
    background_image,
    logo,
    primary_image,
    whatsappButtonTextOne,
    persentationVideo,
    thumbnail,
    activateWhatsApp,
    activateBackgroundIntroductoryText,
    activateBackgroundAccompanyingTextOne,
    activateBackgroundAccompanyingTextTwo,
    activateBackgroundAccompanyingTextThree,
    logoSrc,
  } = landingPageData;
 
  const withBackground = (set) => {
    return set ? "bg-black p-1 rounded-sm bg-opacity-50" : "";
  };

  return (
    <>
      <div className="relative w-full p-5 pt-16 secondary:px-40 secondary:pt-40">
        {logoSrc && (
          <img
            src={logoSrc}
            alt="logo"
            className="absolute h-12 secondary:h-14 left-1/2 translate-x-[-50%] top-2"
          />
        )}

        <div
          style={{
            ...imageBackground(background_image),
          }}
          className="absolute top-0 left-0 right-0 bottom-0  z-[-1]"
        />

        <div className="flex flex-col secondary:gap-24 secondary:flex-row max-w-[1600px] m-auto">
          <div className="flex-1 text-center text-white secondary:text-left secondary:flex secondary:flex-col secondary:justify-between">
            <div
              className={`relative mb-14 text-2xl font-semibold secondary:mb-2 secondary:text-5xl secondary:leading-[60px] ${withBackground(
                transformed_introductoryText != "" &&
                  activateBackgroundIntroductoryText
              )}`}
            >
              {transformed_introductoryText}
              <BlackArrow
                show={
                  transformed_introductoryText != "" &&
                  activateBackgroundIntroductoryText
                }
                type={"finance"}
              />
            </div>
            <div className="flex justify-center secondary:justify-start">
              <div
                className={`relative text-xl mb-14 secondary:text-[28px] secondary:mb-0 ${withBackground(
                  transformed_accompanyingTextOne != "" &&
                    activateBackgroundAccompanyingTextOne
                )}`}
              >
                {transformed_accompanyingTextOne}
                <BlackArrow
                  show={
                    transformed_accompanyingTextOne != "" &&
                    activateBackgroundAccompanyingTextOne
                  }
                  type={"finance"}
                />
              </div>
            </div>
          </div>

          {/** Foto */}
          <div
            className="size-[400px] rounded-xl hidden secondary:block !bg-top"
            style={
              primary_image != "" ? { ...imageBackground(primary_image) } : {}
            }
          ></div>

          {/** Video */}
          <Video
            url={persentationVideo}
            thumbnail={thumbnail}
            className="secondary:hidden"
            textWhite
            type="finance"
            heading={landingPageData.transformed_introVideoHeader}
            textBackground={landingPageData.activateTextWithBackground}
          />

          <div className="secondary:hidden">
            <WhatsappButton
              number={whatsappNumber}
              whatsappText={transformed_whatsappText}
              className="pt-14 secondary:hidden"
              text={whatsappButtonTextOne}
              activateWhatsApp={activateWhatsApp}
              type="finance"
              sendGoogleAnalytics={() =>
                sendEventUser({
                  category: "button",
                  action: `click_wa1`,
                  label: "wa1",
                  value: 1,
                  landingPageId: landingPageData._id,
                })
              }
            />
          </div>
        </div>

        {/**Text */}
        <div className="hidden pt-24 text-[28px] text-center text-white secondary:block max-w-[1600px] mx-auto">
          <div
            className={`mb-3 ${withBackground(
              transformed_accompanyingTextTwo != "" &&
                activateBackgroundAccompanyingTextTwo
            )}`}
          >
            {transformed_accompanyingTextTwo}
          </div>
          <div
            className={`${withBackground(
              transformed_accompanyingTextThree != "" &&
                activateBackgroundAccompanyingTextThree
            )}`}
          >
            {transformed_accompanyingTextThree}
          </div>
        </div>

        {/** Button */}
        <a href="#middle" className="block mt-auto pt-14">
          <img
            src="/images/icons-8-doppelt-runter-21.png"
            alt=""
            className="animate-bounce secondary:w-[80px] sm:w-[50px] w-[32px] m-auto"
          />
        </a>
      </div>
      <div id="middle" />
    </>
  );
};

export default HeroFinance;
