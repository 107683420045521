import { Alert, Button, Checkbox, Divider, Space, message } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/auth/selectors";
import { store } from "../../redux/store";
import PublicService from "../../service/PublicService";
import SubscriptionService from "../../service/SubscriptionService";
import UserService from "../../service/UserService";

const Upgrade = ({ title }) => {
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState();
  const [checked, setChecked] = useState(false);
  const [checkboxFailed, setCheckboxFailed] = useState(false);
  const [adminTestPhase, setAdminTestPhase] = useState(7);
  const [prices, setPrices] = useState({
    month: 299,
    sixMonths: 199,
    twelveMonths: 149,
    title: "Standard",
  });
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox3, setCheckbox3] = useState(true);

  const getPrices = async () => {
    const data = await PublicService.getSubscriptionInfo();
    console.log(data);
    setPrices((prev) => ({ ...prev, ...data?.data }));
  };
  useEffect(() => {
    getPrices();
  }, []);
  const [tab, setTab] = useState("twelveMonths");
  const getSubscriptionInfo = async () => {
    const { data } = await SubscriptionService.getSubscriptionInfo();
    console.log(data);
    setSubscription(data?.subscription);
  };
  React.useEffect(() => {
    title("Upgrade");
    getSubscriptionInfo();
  }, [title]);

  const { user } = useSelector(selectUser);

  useEffect(() => {
    const getAdminConfigure = async () => {
      const response = await PublicService.getAdminConfigure();
      setAdminTestPhase(response?.data?.config?.testPhaseDurationDays);
    };
    getAdminConfigure();
  }, []);
  useEffect(() => {
    const subscribed = !!subscription?.id;
    const isTestPhase = (
      user?.firstAccessAt ? moment(user.firstAccessAt) : moment()
    )
      .add(adminTestPhase, "days")
      .isAfter(moment());
    if (subscribed) navigate("/dashboard");
  }, [user, navigate, adminTestPhase, subscription]);

  const checkInterval = useRef();
  useEffect(() => {
    checkInterval.current = setInterval(async () => {
      const me = await UserService.me();

      const subscribed = !!me?.data?.user?.subscription?.paid;
      if (subscribed) {
        clearInterval(checkInterval.current);
        navigate("/dashboard");
      }
    }, 2000);
    return () => clearInterval(checkInterval.current);
  }, []);

  return (
    <div>
      <div className="  mb-20" id="pricing">
        <h2 className="text-xl text-center  my-1 font-semibold">
          {user.subscribedOnce
            ? "Schön, dass du wieder zurück bist! Um auf das System zugreifen zu können, benötigst du ein aktives Abonemment."
            : "Wähle die Laufzeit für Dein Abonnement"}
        </h2>
        <div className="flex justify-center ">
          <div className="flex rounded-[15px] relative border-[1px] border-[#D8D8D8] transition-all">
            <div
              onClick={() => setTab("month")}
              className={`${
                tab === "month" ? "bg-[#001D47]" : "bg-[#fafafa] !text-black"
              } text-center text-white text-base  cursor-pointer px-5 py-4 rounded-s-[15px] duration-300  transition-all `}
            >
              1 Monat
            </div>
            <div
              onClick={() => setTab("sixMonths")}
              className={`${
                tab === "sixMonths"
                  ? "bg-[#001D47]"
                  : "bg-[#fafafa] !text-black"
              } text-center text-white text-base  cursor-pointer px-5 py-4  duration-300 transition-all`}
            >
              6 Monate
            </div>
            <div
              onClick={() => setTab("twelveMonths")}
              className={`${
                tab === "twelveMonths"
                  ? "bg-[#001D47]"
                  : "bg-[#fafafa] !text-black"
              } text-center text-white text-base  cursor-pointer px-5 py-4 rounded-e-[15px]  duration-300 transition-all`}
            >
              12 Monate
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex secondary:w-[80%] w-full secondary:flex-nowrap flex-wrap m-auto secondary:gap-10 gap-[7rem] secondary:p-0 xl:px-[15rem] justify-center"
        id="pricing"
      >
        <div className="rounded-[20px]  w-full  text-white bg-blue-main  p-[35px]">
          <div className="flex justify-between items-center">
            <h1 className="sm:text-[28px] text-base font-semibold">
              {prices.title}
            </h1>
            <p className=" font-semibold my-5">
              <span className="sm:text-primary text-responsiveHeading  font-bold">
                {user?.canAccessSystemForFree ? "0,50" : prices[tab]}€
              </span>{" "}
              / Monat (zzgl. MwSt)
              {/* 1069€ / Jahr */}
            </p>
          </div>

          <hr className="h-[1px] bg-[#868686] " />
          <h1 className="text-[20px] font-semibold my-5">Features</h1>
          <div className="grid tertiary:grid-cols-4 primary:grid-cols-3 md:grid-cols-2">
            <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-center gap-2">
              <AiOutlineCheckCircle />
              Landingpage Vorlagen
            </p>
            <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-center gap-2">
              <AiOutlineCheckCircle />
              VideoWorkbook
            </p>
            <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-center gap-2">
              <AiOutlineCheckCircle />
              Statistiken
            </p>
            <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-center gap-2">
              <AiOutlineCheckCircle />
              Selektionen
            </p>
            <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-center gap-2">
              <AiOutlineCheckCircle />
              kostenloses Onboarding
            </p>
            <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-center gap-2">
              <AiOutlineCheckCircle />
              Leistungsformel
            </p>
            <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-center gap-2">
              <AiOutlineCheckCircle />
              Nachrichtensystem
            </p>
            <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-center gap-2">
              <AiOutlineCheckCircle />
              Prämiensystem
            </p>
            <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-center gap-2">
              <AiOutlineCheckCircle />
              Benachrichtigungssytem
            </p>
            <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-center gap-2">
              <AiOutlineCheckCircle />
              Automatisierungen
            </p>
            <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-center gap-2">
              <AiOutlineCheckCircle />
              Nachrichtenvorlagen
            </p>
            <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-center gap-2">
              <AiOutlineCheckCircle />
              Kampagnen Basic
            </p>
            <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-center gap-2">
              <AiOutlineCheckCircle />
              Skriptvorlagen
            </p>
          </div>

          <div className="mt-10 flex items-start justify-start tertiary:gap-4 gap-1">
            <Checkbox
              value={checkbox1}
              onChange={(e) => setCheckbox1(e.target.checked)}
            />
            <p className="text-ellipsis text-start hyphens-auto ">
              Ich akzeptiere die AGB's von EinGuterTipp
            </p>
          </div>
          {/* <div className="mt-2 flex items-start justify-start tertiary:gap-4 gap-1">
            <Checkbox
              value={checkbox3}
              onChange={(e) => setCheckbox3(e.target.checked)}
            />
            <p className="text-ellipsis text-start hyphens-auto ">
              Der Vertrag wird zum Ende der gewählten Laufzeit automatisch
              verlängert, bis Du die Funktion ausschaltest
            </p>
          </div> */}

          <Button
            type="primary"
            className="mt-5 mb-1 h-10 w-full"
            onClick={async () => {
              if (!checkbox1 || !checkbox3) return setCheckboxFailed(true);

              const { data } = await SubscriptionService.subscribe(
                tab,
                prices._id,
                `${window.location.origin}/dashboard`
              );

              window.location.href = data.paymentLink;
            }}
          >
            Abonnieren
          </Button>

          {checkboxFailed && (
            <Alert type="error" message="Du muss allen Checkboxen zustimmen" />
          )}
        </div>
      </div>

      {/* {!user?.subscribedOnce && (
        <>
          <div className="mt-10 ">
            <Divider>
              ODER Du benötigst noch mehr Informationen für Deine Entscheidung?
            </Divider>
          </div>

          <div className="w-full justify-center flex">
            <a href="/erstgesprach">
              <Button type="primary" className="mt-5 mb-1 h-10 w-full">
                Dann Erstgespräch buchen
              </Button>
            </a>
          </div>
        </>
      )} */}
    </div>
  );
};

export default Upgrade;
