import React, {
  forwardRef,
  useImperativeHandle,
  useReducer,
  useRef,
} from "react";
import { stripObjectKeep } from "../../../../utils/StripObject";
import ClipboardButtonsBar from "../../../Dashboard/kampaign/ClipboardButtonsBar";
import Box from "../Layout/Box";
import MediaUpload from "../shared/MediaUpload";
import SwitchInput from "../shared/SwitchInput";
import ToggleAndText from "../shared/ToggleAndText";

const HeroConfig = forwardRef(({ landingPageData, type }, ref) => {
  const [heroConfig, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "SET":
        return action.payload.data;
      case "SET_KEY":
        return { ...state, [action.payload.key]: action.payload.value };
    }
  }, stripObjectKeep(landingPageData, ["primary_image", "logoSrc", "background_image", "introductoryText", "accompanyingTextOne", "accompanyingTextTwo", "accompanyingTextThree", "logo", "activateBackgroundIntroductoryText", "activateBackgroundAccompanyingTextOne", "activateBackgroundAccompanyingTextTwo", "activateBackgroundAccompanyingTextThree"]));

  const primaryImageRef = useRef(null);
  const backgroundImageRef = useRef(null);
  const logoRef = useRef(null);
  const isTextContrasRelevant = type === "finance" || type === "stormgas";

  useImperativeHandle(ref, () => ({
    async save() {
      console.log("heroConfig.save()");

      // Saving images
      const [newPrimaryImage, newBackgroundImage, newLogo] = await Promise.all([
        primaryImageRef.current.saveLocalToCloud(),
        backgroundImageRef.current.saveLocalToCloud(),
        logoRef.current.saveLocalToCloud(),
      ]);

      // Aktualisiere die Landing Page mit den neuen Bild-URLs
      return {
        landingPageData: {
          ...heroConfig,
          ...(newPrimaryImage.success && {
            primary_image: newPrimaryImage.secure_url,
          }),
          ...(newBackgroundImage.success && {
            background_image: newBackgroundImage.secure_url,
          }),
          ...(newLogo.success && {
            logoSrc: newLogo.secure_url,
          }),
        },
      };
    },
  }));

  return (
    <Box className="flex flex-col gap-10">
      <ImageUploadRow
        uploadRef={primaryImageRef}
        text="Lade hier ein möglichst charismatisches Bild von Dir hoch. Suche im
        Zweifel einen Profi auf."
        url={heroConfig.primary_image}
        setUrl={(newUrl) => {
          dispatch({
            type: "SET_KEY",
            payload: { key: "primary_image", value: newUrl },
          });
        }}
        textSrcNotThere="Foto Hochladen"
        textSrcThere="Primärbild ändern"
        imageUploadId="upload-landingpageconfig-first"
      />

      <ImageUploadRow
        uploadRef={backgroundImageRef}
        text="Lade hier Dein individuelles Hintergrundbild hoch"
        url={heroConfig.background_image}
        setUrl={(newUrl) =>
          dispatch({
            type: "SET_KEY",
            payload: { key: "background_image", value: newUrl },
          })
        }
        textSrcNotThere="Hintergrundbild hochladen"
        textSrcThere="Hintergrundbild ändern"
        imageUploadId="upload-background-landingpageconfig-first"
      />

      <ImageUploadRow
        uploadRef={logoRef}
        text="Lade hier Dein Logo hoch sofern Du es anzeigen möchtest"
        url={heroConfig.logoSrc}
        setUrl={(newUrl) =>
          dispatch({
            type: "SET_KEY",
            payload: { key: "logoSrc", value: newUrl },
          })
        }
        textSrcNotThere="Logo hochladen"
        textSrcThere="Logo ändern"
        imageUploadId="upload-logo-landingpageconfig-first"
        emptyCheck={false}
      />

      {/* Clipboard */}
      <ClipboardButtonsBar
        full={true}
        names={["Promoter Vorname", "Promoter Nachname"]}
      />

      {/* Inputs */}
      <SwitchInput
        checked={heroConfig.activateBackgroundIntroductoryText}
        switchLabel="Text Schattierung"
        onChangeSwitch={(e) => {
          dispatch({
            type: "SET_KEY",
            payload: { key: "activateBackgroundIntroductoryText", value: e },
          });
        }}
        hasToggle={isTextContrasRelevant}
        label="Einleitungstext (Formuliere einen ganzen Satz)"
        type="textarea"
        value={heroConfig.introductoryText}
        onChange={(e) => {
          dispatch({
            type: "SET_KEY",
            payload: { key: "introductoryText", value: e.target.value },
          });
        }}
      />

      <SwitchInput
        checked={heroConfig.activateBackgroundAccompanyingTextOne}
        switchLabel="Text Schattierung"
        onChangeSwitch={(e) => {
          dispatch({
            type: "SET_KEY",
            payload: { key: "activateBackgroundAccompanyingTextOne", value: e },
          });
        }}
        hasToggle={isTextContrasRelevant && type !== "stormgas"}
        label="Begleittext 1 (Formuliere einen ganzen Satz)"
        type="textarea"
        value={heroConfig.accompanyingTextOne}
        onChange={(e) => {
          dispatch({
            type: "SET_KEY",
            payload: { key: "accompanyingTextOne", value: e.target.value },
          });
        }}
      />

      <SwitchInput
        checked={heroConfig.activateBackgroundAccompanyingTextTwo}
        switchLabel="Text Schattierung"
        onChangeSwitch={(e) => {
          dispatch({
            type: "SET_KEY",
            payload: { key: "activateBackgroundAccompanyingTextTwo", value: e },
          });
        }}
        hasToggle={isTextContrasRelevant}
        label="Begleittext 2 (Formuliere einen ganzen Satz)"
        type="textarea"
        value={heroConfig.accompanyingTextTwo}
        onChange={(e) => {
          dispatch({
            type: "SET_KEY",
            payload: { key: "accompanyingTextTwo", value: e.target.value },
          });
        }}
      />

      <SwitchInput
        checked={heroConfig.activateBackgroundAccompanyingTextThree}
        switchLabel="Text Schattierung"
        onChangeSwitch={(e) => {
          dispatch({
            type: "SET_KEY",
            payload: {
              key: "activateBackgroundAccompanyingTextThree",
              value: e,
            },
          });
        }}
        hasToggle={isTextContrasRelevant}
        label="Begleittext 3 (Formuliere einen ganzen Satz)"
        type="textarea"
        value={heroConfig.accompanyingTextThree}
        onChange={(e) => {
          dispatch({
            type: "SET_KEY",
            payload: { key: "accompanyingTextThree", value: e.target.value },
          });
        }}
      />
    </Box>
  );
});

const ImageUploadRow = ({
  text,
  uploadRef,
  url,
  setUrl,
  textSrcNotThere,
  textSrcThere,
  imageUploadId,
  emptyCheck = true,
}) => {
  return (
    <div className="flex flex-col min-h-[200px] items-center gap-4 primary:gap-10 primary:flex-row">
      {/**Text */}
      <div className="w-full text-base font-semibold text-center lg:text-9xl sm:text-xl primary:text-left">
        {text}
      </div>

      {/**Image Upload */}
      <MediaUpload
        ref={uploadRef}
        id={imageUploadId}
        url={url}
        setUrl={setUrl}
        textSrcNotThere={textSrcNotThere}
        textSrcThere={textSrcThere}
        emptyCheck={emptyCheck}
      />
    </div>
  );
};

export default HeroConfig;
