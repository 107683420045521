import React, { useEffect, useState } from "react";
import HTMLTextEditor from "./HtmlEditor";
import CollapseItem from "./CollapseItem";
import FloatLabel from "../../FloatingLabel";
import { Button } from "antd";
import AdminService from "../../../service/AdminService";

const Index = () => {
  const editors = ["Datenschutz-Text", "Impressum-Text", "AGBs"];
  const [config, setConfig] = useState({});
  const [formDataLoading, setFormDataLoading] = useState(false);

  const getAdminSetting = async () => {
    const { data } = await AdminService.getAdminSetting();
    setConfig(data);
  };

  const configChange = async (idx, data) => {
    if (config && config["CMS"]) {
      config["CMS"][cmsKeys[idx]] = data;
      setConfig({ ...config });
    }
  };

  const saveConfig = async (idx, data) => {
    AdminService.setAdminConfiguration({
      key: "CMS." + cmsKeys[idx],
      value: data,
    });
  };

  useEffect(() => {
    getAdminSetting();
  }, []);

  const cmsKeys = ["privacyPolicy", "impressum", "terms"];

  return (
    <div>
      <CollapseItem title={"Rechtliches"}>
        <div className="p-8 flex flex-col gap-8">
          {editors.map((e, i) => (
            <HTMLTextEditor
              label={e}
              key={i}
              value={config && config["CMS"] ? config["CMS"][cmsKeys[i]] : ""}
              configChange={(data) => {
                configChange(i, data);
              }}
              saveConfig={(data) => {
                saveConfig(i, data);
              }}
            />
          ))}
        </div>
      </CollapseItem>
      <div className="my-8">
        <CollapseItem title={"Testphase"}>
          <div className="p-8 flex items-center justify-start gap-6">
            <div className="w-[216px]">
              <FloatLabel
                label={"Dauer der Testphase"}
                keepitfocused
                unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] w-[216px] !font-semibold  "
                className={" flex-grow"}
              >
                <input
                  type="text"
                  // defaultValue={"7"}
                  value={config ? config.testPhaseDurationDays : 0}
                  onChange={(e) => {
                    setConfig({
                      ...config,
                      testPhaseDurationDays: e.target.value,
                    });
                  }}
                  className="border-[1px] text-xl text-text-sec bg-transparent w-[216px] border-[#d8d8d8] outline-none rounded-[15px] p-[30px_30px]  h-[87px]"
                />
              </FloatLabel>
            </div>
            <div className="text-9xl font-semibold">+</div>
            <p className="text-xl text-trkis font-semibold">Tage</p>
          </div>
          <div className="p-8">
            <Button
              type="primary"
              loading={formDataLoading}
              className="h-[46px] w-full"
              onClick={() => {
                setFormDataLoading(true);
                AdminService.setAdminConfiguration({
                  key: "testPhaseDurationDays",
                  value: parseInt(config.testPhaseDurationDays),
                });
                setFormDataLoading(false);
              }}
            >
              Speichern
            </Button>
          </div>
        </CollapseItem>
      </div>
    </div>
  );
};

export default Index;
