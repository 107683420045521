import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { replaceAndMark } from "../../../../../utils/replaceAndMarkText";
import { replaceText } from "../../../../../utils/replaceText";
import TypeButton from "../../shared/TypeButton";
import Video from "../../shared/Video";
import WhatsappButton from "../../shared/WhatsappButton";
import Footer from "../footer/Footer";
import Hero from "../hero/Hero";
import LocalReviews from "../localReviews/LocalReviews";
import PortraitAndText from "../portraitAndText/PortraitAndText";

import { sendEventUser } from "../../../../../utils/analytics";

const View = ({
  landingPageData,
  clickid,
  promoter,
  localReviews,
  type,
  promoter_id,
  landingPageId,
  user,
}) => {
  const navigate = useNavigate();
  const {
    activateLocalReviews,
    whatsappButtonTextOne,
    whatsappButtonTextTwo,
    persentationVideo,
    presentationVideoDuration,
    thumbnail,
    questionaireIntroductionText,
    questionaireButtonText,
    whatsappText,
    whatsappNumber,
    activateWhatsApp,
    logo,
    localReviewsHeader,
    introVideoHeader,
  } = landingPageData;

  // Textersätzung...
  [
    "introductoryText",
    "accompanyingTextOne",
    "accompanyingTextTwo",
    "accompanyingTextThree",
  ].forEach((key) => {
    let temp = replaceAndMark(
      landingPageData[key],
      {
        "{Promoter Vorname}": ` ${promoter.firstname} `,
        "{Promoter Nachname}": ` ${promoter.lastname} `,
      },
      "#00DEF7"
    );
    landingPageData[`transformed_${key}`] = temp;
  });

  landingPageData[`transformed_whatsappText`] = replaceText(whatsappText, {
    "{Promoter Vorname}": ` ${promoter.firstname} `,
    "{Promoter Nachname}": ` ${promoter.lastname} `,
  });

  landingPageData[`transformed_introVideoHeader`] = replaceText(
    introVideoHeader,
    {
      "{Videolaenge}": Math.round(presentationVideoDuration),
    }
  );

  // Google Analytics PageView
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      sendEventUser({
        category: "page",
        action: "page_viewLP",
        label: "lppageview",
        value: 1,
        landingPageId: landingPageId,
      });
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [landingPageId]);

  // Google Analytics Handler
  const googleAnalyticsHandler = (label) => {
    sendEventUser({
      category: "button",
      action: `click_${label}`,
      label: label,
      value: 1,
      landingPageId: landingPageId,
    });
  };

  return (
    <div className="w-full">
      <Hero landingPageData={landingPageData} type={type} />

      <div className={type === "property" ? "bg-[#F1F3F6]" : "bg-white"}>
        <WhatsappButton
          number={whatsappNumber}
          whatsappText={landingPageData.transformed_whatsappText}
          text={whatsappButtonTextOne}
          activateWhatsApp={activateWhatsApp}
          className="hidden secondary:flex py-14"
          type={type}
          sendGoogleAnalytics={() => googleAnalyticsHandler("wa1")}
        />

        <LocalReviews
          localReviews={localReviews}
          activateLocalReviews={activateLocalReviews}
          localReviewsHeader={localReviewsHeader}
          type={type}
          logo={logo}
        />

        <Video
          url={persentationVideo}
          thumbnail={thumbnail}
          className="hidden secondary:block pt-14"
          type={type}
          heading={landingPageData.transformed_introVideoHeader}
          landingPageId={landingPageId}
        />

        <WhatsappButton
          activateWhatsApp={activateWhatsApp}
          number={whatsappNumber}
          whatsappText={landingPageData.transformed_whatsappText}
          text={whatsappButtonTextTwo}
          className="mx-4 mt-14"
          type={type}
          sendGoogleAnalytics={() => googleAnalyticsHandler("wa2")}
        />

        <PortraitAndText
          landingPageData={landingPageData}
          className="block secondary:hidden pt-14"
        />

        <img
          src="/images/icons-8-doppelt-runter-21-schwarz.png"
          alt=""
          className="animate-bounce secondary:w-[80px] sm:w-[50px] w-[32px] m-auto mt-14"
        />

        <div className="text-2xl font-bold text-center secondary:text-4xl mt-14 p-7 primary:px-40">
          {questionaireIntroductionText}
        </div>

        <TypeButton
          className="mx-4 mt-4 mb-14"
          type={type}
          text={questionaireButtonText}
          onClick={() => {
            googleAnalyticsHandler("qf");
            navigate(
              `/questionare/${type}/${landingPageId}/${promoter_id}/${clickid}`
            );
          }}
        />

        <Footer
          user={user}
          ladingPageId={landingPageId}
          landingPageData={landingPageData}
          promoter_id={promoter_id}
          type={type}
        />
      </div>
    </div>
  );
};

export default View;
