import React, { useEffect, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import CrudService from "../../../service/CrudService";
import PublicService from "../../../service/PublicService";
import CollapseItem from "./CollapseItem";
import ReviewSlider from "./ReviewSlider";
import { sendEventEGT } from "../../../utils/analytics";
const items = [
  {
    title: "Muss ich etwas installieren?",
    desc: "Um EinGuterTipp nutzen zu können, brauchst du keine Software installieren. Du kannst es auf allen Geräten nutzen.",
  },
  {
    title: "Brauche ich das Coaching zwingend?",
    desc: "Nein. Mit der Nutzung erhältst Du Zugang zu einem sehr schlanken Videokurs mit Gesprächsleitfäden und 1:1 Umsetzungserklärungen, damit Du schnell und unkompliziert starten kannst. Mit dem kostenlosen Onboarding findest Du Dich auch umgehend zurecht. So hast du alles für einen guten Start. Das Coaching kannst du bei Bedarf jederzeit nachordern.",
  },
  {
    title: "Sind meine Kundendaten sicher?",
    desc: "Selbstverständlich. Deinen Daten liegen auf Deutschen und Europäischen Sicherheitsservern. So werden höchste Sicherheit und die DSGVO gewährleistet.",
  },
  {
    title: "Brauche ich dafür eine Webseite ?",
    desc: "Du brauchst weder eine Webseite, noch sonst eine digitale Präsens. Du brauchst auch keine besonderen Kenntnisse oder Fähigkeiten im Umgang mit Computern oder Software.",
  },
];

const priceText = {
  month: "Du bist Dir unsicher und möchtest erstmal reinschnuppern",
  sixMonths: "Die goldene Mitte: Preislich attraktiv ohne lange Laufzeiten",
  twelveMonths:
    "Für Preisbewusste, die wissen was sie wollen und richtig durchstarten möchten",
};
const Index = () => {
  const [prices, setPrices] = useState({
    month: 299,
    sixMonths: 199,
    twelveMonths: 149,
    title: "Standard",
  });
  const getPrices = async () => {
    const data = await PublicService.getSubscriptionInfo();
    setPrices((prev) => ({ ...prev, ...data?.data }));
  };
  useEffect(() => {
    getPrices();
  }, []);
  const [textValue, setTextValue] = useState("");
  const [email, setEmail] = useState("");
  const [supportEmail, setSupportEmail] = useState("");
  const [tab, setTab] = useState("twelveMonths");

  const googleAnalyticsHandler = (label) => {
    sendEventEGT({
      category: "button",
      action: `click-${label}`,
      label: label,
      value: 1,
    });
  };

  return (
    <div className=" rounded-[30px] bg-white" id="onboard">
      <div className="gray-bg-gradient sm:p-[6rem]  sm:w-[90%] w-[99%] m-auto">
        <h1 className="font-bold text-center xl:text-primary text-responsiveHeading sm:text-start ">
          <span>Wir machen Dich fit</span>
        </h1>
        <div className="my-10 sm:w-auto w-[80%] font-semibold text-[#868686] text-base sm:text-start text-center sm:text-[20px] sm:m-0 m-auto ">
          <p>
            Wichtiger als die Tatsache das Du unsere Software nutzt ist, dass du
            Erfolg hast. Deshalb bekommst Du nicht nur die Software, sondern
            auch ein Onboarding, damit Du dich direkt zurecht findest. Über
            unseren knackigen Videokurs erhältst Du eine Schritt für Schritt
            Anleitung für schnelle und erfolgreiche Umsetzung ohne unnötiges
            „blabla“
          </p>
        </div>

        <div className="my-10 sm:text-start text-center sm:w-auto w-[80%] m-auto">
          <h1 className="text-xl font-bold sm:text-primary">Der Ablauf</h1>
        </div>
        <div className="m-auto  w-[400px] xl:w-full rounded-[30px] relative flex flex-wrap px-[4rem] xl:flex-nowrap xl:gap-0 gap-20  bg-white justify-between overflow-hidden py-10">
          <div className="flex flex-col items-center justify-center w-full">
            <h1 className="text-center text-[20px] font-semibold">
              Erstgespräch
            </h1>
            <img
              src="/images/morflax-studio-18 1.png"
              alt=""
              className="mt-2 w-[250px]"
            />
          </div>
          <hr className="bg-[#D8D8D8] text-[#D8D8D8]  rotate-90 w-[80%] m-auto xl:block hidden" />
          <div className="flex flex-col items-center justify-center w-full">
            <h1 className="text-center text-[20px] font-semibold">
              <div>Nachdenken & </div>
              <div>richtig entscheiden</div>
            </h1>
            <img
              src="/images/settingchaticons.png"
              alt=""
              className="mt-10 w-[250px]"
            />
          </div>
          <hr className="bg-[#D8D8D8] text-[#D8D8D8] rotate-90 w-[80%] overflow-hidden m-auto xl:block hidden" />
          <div className="flex flex-col items-start w-full h-full ">
            <h1 className=" w-full text-[20px]  text-center font-semibold">
              Empfehlungen erhalten
            </h1>
            <div className="xl:hidden flex h-[400px]"></div>
            <img
              src="/images/myhandwithheart.png"
              className="mt-10 xl:w-[330px] w-[450px]  m-auto absolute  xl:right-0 xl:bottom-[-15%] bottom-0 right-0"
              alt=""
            />
          </div>
        </div>

        <div className="my-10 sm:text-start text-center sm:w-auto w-[80%] m-auto">
          <h1 className="text-xl font-bold sm:text-primary">
            Worauf Du zählen kannst!
          </h1>
          <div className="my-10 font-semibold text-base sm:text-xl text-[#868686] w-full">
            <p>
              Unser Support unterstützt Dich{" "}
              <img
                src="/images/logo.png"
                alt=""
                srcSet=""
                width="20"
                className="inline"
              />
              -voll bei all Deinen Fragen. So kannst Du direkt einfach und
              unkompliziert starten. Kein langes suchen, kein langes oder
              kompliziertes einarbeiten, sondern direkt loslegen!
            </p>
          </div>
        </div>
        <div className="my-10 grid xl:grid-cols-2 xl:grid-rows-2 grid-cols-1 grid-rows-4  gap-3 text-center text-base sm:text-xl font-semibold  sm:w-auto w-[80%] m-auto">
          <div className="rounded-[20px] border-[1px] px-16 py-10 bg-white border-[#D8D8D8] flex items-center">
            Einfache Tutorials zum simplen nachmachen
          </div>
          <div className="rounded-[20px] border-[1px] px-16 py-10 bg-white border-[#D8D8D8] flex items-center">
            Schritt für Schritt Anleitungen aus der Praxis für die Praxis
          </div>
          <div className="rounded-[20px] border-[1px] px-16 py-10 bg-white border-[#D8D8D8] flex items-center">
            1000fach erprobte Gesprächsvorlagen für die richtige Kommunikation
            mit Deinen Kunden
          </div>
          <div className="rounded-[20px] border-[1px] px-16 py-10 bg-white border-[#D8D8D8] flex items-center">
            Auch für Software-Hasser zu 100% geeignet
          </div>
        </div>
      </div>{" "}
      <div className="m-auto my-20  sm:w-[90%]   w-[80%] ">
        <div className="makeyoufit-card text-center font-bold xl:text-[46px] text-[26px] md:text-[32px] text-white xs:py-14 py-6 xs:px-16 px-0">
          <h1 className="w-[60%]  m-auto my-10">
            Stell Dir vor, Deine Empfehlungen würden sich bei Dir melden?
          </h1>
        </div>

        <div className="my-20 sm:mt-[14rem] flex-col flex relative">
          <div
            className="static block sm:hidden rounded-3xl aspect-square"
            style={{
              backgroundImage: "url(/images/frame-126@2x.png)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top",
            }}
          ></div>
          <div className="grub-dich-card secondary:w-[90%] w-full rounded-[30px] text-white sm:px-10 px-3 py-4 sm:py-12">
            <div className="w-[90%] flex">
              <div className="w-full xl:w-3/4 secondary:w-full">
                <h1 className="font-bold text-white text-responsiveHeading sm:text-primary">
                  Grüß Dich!
                </h1>
                <p className="sm:text-[1.25rem] text-base primary:w-[90%] w-auto font-[600] my-10 text-[#D8D8D8]">
                  “Seit 23 Jahren bin ich Finanzdienstleister mit Leib und
                  Seele. Es gibt eine Menge Gründe Empfehlungsmarketing heute
                  völlig anders zu denken! Aus genau diesem Grund gibt es
                  EinGuterTipp und ich würde mich freuen Dich sehr bald an Board
                  begrüßen zu dürfen. Mein Versprechen an Dich - Du freust Dich
                  sogar mehr als ich!” 😄
                </p>
                <h2 className="flex items-start gap-2 my-10">
                  <img
                    src="/images/icon03-21.png"
                    alt="heart"
                    className="mt-1"
                  />
                  <p className="sm:text-[1.25rem] text-base font-[600]">
                    Sascha Emmerich, Founder
                  </p>
                </h2>
              </div>
              <div className="items-center justify-center hidden w-0 xl:w-1/4 secondary:hidden xl:flex">
                <img
                  src="/images/frame-126@2x.png"
                  alt=""
                  className=" hidden secondary:hidden xl:block w-[400px] "
                />
              </div>
            </div>
            <img
              src="/images/frame-126@2x.png"
              alt=""
              className="secondary:absolute  secondary:block hidden  right-[0] top-[-100px] w-[300px] static"
            />
          </div>
        </div>
        <h1 className="my-10 font-bold sm:text-primary text-responsiveHeading">
          Häufige Fragen:
        </h1>
        <div className="transition-all ">
          {items.map((e, i) => (
            <CollapseItem key={i} {...e} />
          ))}
        </div>
        <div className="px-3 py-5 mt-[7rem] font-bold text-center text-white makeyoufit-second-card text-responsiveHeading xl:text-primary sm:py-10 sm:px-16 ">
          <div className="">
            <h1> Keine teuren Leads</h1>
            <h1>kaufen müssen um neue Kunden zu gewinnen.</h1>
          </div>
          <button
            onClick={() => googleAnalyticsHandler("reg2")}
            className="bg-[#FFD814] hover:bg-[#f7ca00] !text-[#000000] text-base sm:text-[20px] mt-10  py-[1.25rem] px-[2.1875rem] rounded-[3.125rem]"
          >
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.eingutertipp.de/register"
            >
              Jetzt Kunden gewinnen
            </a>
          </button>
        </div>

        <div className=" mt-[7rem] mb-20" id="pricing">
          <h1 className="font-bold text-center sm:text-primary text-responsiveHeading ">
            Was drin ist
          </h1>
          <h2 className="text-xl text-center text-[#868686] my-10 font-semibold">
            Wähle zwischen mehreren Laufzeitangeboten
          </h2>
          <div className="flex justify-center ">
            <div className="flex rounded-[15px] relative border-[1px] border-[#D8D8D8] transition-all">
              <div
                onClick={() => setTab("month")}
                className={`${
                  tab === "month" ? "bg-[#001D47]" : "bg-[#fafafa] !text-black"
                } text-center text-white text-base  cursor-pointer px-5 py-4 rounded-s-[15px] duration-300  transition-all `}
              >
                1 Monat
              </div>
              <div
                onClick={() => setTab("sixMonths")}
                className={`${
                  tab === "sixMonths"
                    ? "bg-[#001D47]"
                    : "bg-[#fafafa] !text-black"
                } text-center text-white text-base  cursor-pointer px-5 py-4  duration-300 transition-all`}
              >
                6 Monate
              </div>
              <div
                onClick={() => setTab("twelveMonths")}
                className={`${
                  tab === "twelveMonths"
                    ? "bg-[#001D47]"
                    : "bg-[#fafafa] !text-black"
                } text-center text-white text-base  cursor-pointer px-5 py-4 rounded-e-[15px]  duration-300 transition-all`}
              >
                12 Monate
              </div>
            </div>
          </div>

          <div className="w-full flex justify-center">
            <div className="text-xl text-center  mt-5 font-semibold text-[#001D47] bg-[#fafafa] px-2 py-2 rounded-[15px] border-[1px] border-[#D8D8D8]">
              {priceText[tab]}
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex secondary:w-[80%]  secondary:flex-nowrap flex-wrap m-auto secondary:gap-10 gap-[1rem] secondary:p-0  xl:px-[15rem] primary:px-20 justify-center sm:mt-[80px]"
        id="pricing"
      >
        <div className="rounded-[20px]  w-[320px]  p-[35px] bg-[#0084F7] text-[#fff]">
          <h1 className="sm:text-[28px] text-xl font-semibold text-white">
            Standard
          </h1>
          <p className="my-1 font-semibold">
            <br />
          </p>
          <hr
            className="h-[1px] bg-[#fafafa] text-[#fafafa]"
            style={{ marginTop: 18 }}
          />
          <h1 className="text-[20px] font-semibold my-5">Features</h1>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            kostenloses Onboarding
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Skriptvorlagen
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            VideoWorkbook
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Landingpage Vorlagen
          </p>

          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Statistiken
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Selektionen
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Leistungsformel
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Nachrichtensystem
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Prämiensystem
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Benachrichtigungssytem
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Automatisierungen
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Nachrichtenvorlagen
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Kampagnen Basic
          </p>
        </div>

        <div className="rounded-[20px] relative  w-[320px] sm:my-0 my-20  p-[35px] bg-[#001D47] text-white ">
          {/* <img
            src="/images/premium.png"
            alt=""
            className="sm:hidden block absolute sm:top-[-100px] top-[-50px]  right-[-20px]  "
          /> */}

          <h1 className="sm:text-[28px] text-xl font-semibold">Coaching</h1>
          <p className="my-1 font-semibold">
            <br />
          </p>
          <hr
            className="h-[1px] bg-[#fafafa] text-[#fafafa]"
            style={{ marginTop: 18 }}
          />
          <h1 className="text-[20px] font-semibold my-5">Features</h1>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            1:1 Coaching
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Videokurs deluxe
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            wöchentliche LiveCalls
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            VideoWorkshop
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Drehbuchsupport
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Individual Landingpages
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Skripte & Vorlagen
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Verkauf
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Kundenbroschüre
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Bestandsumsetzung
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Expertenstatus
          </p>
        </div>

        <div className="rounded-[20px]  w-[320px]  p-[35px] bg-[#fafafa]">
          <h1 className="sm:text-[28px] text-xl font-semibold">Enterprise</h1>
          <p className="mb-1 font-semibold text-sm">
            <br />
            (Nur für Unternehmen und Gruppen)
          </p>
          <hr className="h-[1px]" />
          <h1 className="text-[20px]  font-semibold my-5">Features</h1>
          <p className="flex text-[#00DEF7] text-sm sm:text-[16px] font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Standard +
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            internes Onboarding
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            ⁠interne LiveCalls
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            ⁠internes Coaching
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            ⁠1:1 Coachings
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            ⁠digitalisierte Arbeitsprozesse
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Videoworkshops
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Firmenlogo
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            CI Skripe & Vorlagen
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Leads
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Teams
          </p>
        </div>
      </div>
      <div className="flex secondary:w-[80%]  secondary:flex-nowrap flex-wrap m-auto secondary:gap-10 gap-[1rem] secondary:p-0  xl:px-[15rem] primary:px-20 justify-center sm:mt-[20px]">
        <button
          onClick={() => googleAnalyticsHandler("eg4")}
          className=" bg-[#FFD814] hover:bg-[#f7ca00] px-9 !text-[#000000]  animate-jump-in py-[10px] justify-center items-center flex text-center  secondary:py-[20px]   rounded-[50px] text-white font-[600] text-[20px] mt-8 "
        >
          <a href="/erstgesprach">Kostenloses Erstgespräch</a>
        </button>
      </div>
    </div>
  );
};

export default Index;
